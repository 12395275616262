import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  selectedClass = "bg-primary-light-5";
  static targets = ["details"]

  connect() {
    this.highlightCurrentPreview();
  }

  highlightCurrentPreview() {
    if (this.hasDetailsTarget) {
      const detailsId = this.detailsTarget.id.split("_")[2]
      const previewElement = document.getElementById(`conversation_preview_${detailsId}`)
      if (previewElement) {
        previewElement.classList.add(this.selectedClass)
      }
    }
  }

  selectConversation(event) {
    const previews = document.querySelectorAll(".conversation-preview");
    previews.forEach(preview => preview.classList.remove(this.selectedClass));

    event.currentTarget.classList.add(this.selectedClass);
  }
}
